// @deprecated
import { KlevuConfig } from '@klevu/core';
import RailsVars from './railsVariables.js.erb';

globalThis.HV = globalThis.HV || {};
globalThis.HV.PAGE_URL = RailsVars.PAGE_URL;
globalThis.HV.BASE_API_URL = RailsVars.BASE_API_URL;
globalThis.HV.ANALYTICS_API_URL = RailsVars.ANALYTICS_API_URL;
globalThis.HV.ANALYTICS_API_APP_ID = RailsVars.ANALYTICS_API_APP_ID;
globalThis.HV.BASE_LAC_LONG_QUAN_URL = RailsVars.BASE_LAC_LONG_QUAN_URL;
globalThis.HV.COUNTRY_CODE = RailsVars.COUNTRY_CODE;
globalThis.HV.COMPANY_NAME = RailsVars.COMPANY_NAME;
globalThis.HV.HIPVAN_EMAIL = RailsVars.HIPVAN_EMAIL;
globalThis.HV.ALLOW_ZD_WIDGET = true;

globalThis.HV.GROUPINGS = globalThis.HV.GROUPINGS || {};
globalThis.HV.FACEBOOK_APP_ID = RailsVars.FACEBOOK_APP_ID;
globalThis.HV.STORE_COUNTRY = RailsVars.STORE_COUNTRY;
globalThis.HV.KLEVU_API_KEY = RailsVars.KLEVU_API_KEY;

globalThis.lazySizesConfig = globalThis.lazySizesConfig || {};
globalThis.lazySizesConfig.customMedia = {
  '--small': '(max-width: 767px)',
  '--medium': '(min-width: 768px) and (max-width: 991px)',
  '--large': '(min-width: 992px)'
};

globalThis.HV.HIT_TYPES = {
  view: 'view',
  ecommerce: 'ecommerce',
  community: 'community',
  custom: 'custom'
};

globalThis.HV.SEARCH_SUGGESTED_VALUE = RailsVars.SEARCH_SUGGESTED_VALUE;

globalThis.HV.OFFICE_CHAIR_RENTAL_FORM_URL =
  RailsVars.OFFICE_CHAIR_RENTAL_FORM_URL;

globalThis.HV.HEADER_HEIGHT = 0;
globalThis.HV.BREAKPOINT = null;
globalThis.HV.CURRENCY = RailsVars.CURRENCY;
globalThis.HV.CURRENCY_CODE = RailsVars.CURRENCY_CODE;
globalThis.HV.CURRENCY_SYMBOL = RailsVars.CURRENCY_SYMBOL;
globalThis.HV.PAGE_VIEW_KEY = RailsVars.PAGE_VIEW_KEY;
globalThis.HV.LAST_REAL_PAGE_VIEW_KEY = RailsVars.LAST_REAL_PAGE_VIEW_KEY;
globalThis.HV.STRIPE_KEY = RailsVars.STRIPE_KEY;
globalThis.HV.PAYMENT_TYPES = RailsVars.PAYMENT_TYPES;
globalThis.HV.APP_URL_VIA_APPSFLYER = RailsVars.APP_URL_VIA_APPSFLYER;
globalThis.HV.CASUAL_LOW_STOCK_ALERT = parseInt(
  RailsVars.CASUAL_LOW_STOCK_ALERT,
  10
);
globalThis.HV.URGENT_LOW_STOCK_ALERT = parseInt(
  RailsVars.URGENT_LOW_STOCK_ALERT,
  10
);
globalThis.HV.FLAG_SHIP_DISCOUNT_CODE = RailsVars.FLAG_SHIP_DISCOUNT_CODE;
globalThis.HV.AUTH_LOCATION = null;
globalThis.HV.AUTH_TRIGGER_TYPE = null;
globalThis.HV.AUTH_TRIGGER_ID = null;

globalThis.HV.AIRBRAKE_JS_ID = RailsVars.AIRBRAKE_JS_ID;
globalThis.HV.AIRBRAKE_JS_KEY = RailsVars.AIRBRAKE_JS_KEY;

globalThis.HV.GOOGLE_OAUTH_CLIENT_ID = RailsVars.GOOGLE_OAUTH_CLIENT_ID;

globalThis.HV.Components = {};
globalThis.HV.Utils = {
  ...globalThis.HV.Utils,
  // eslint-disable-next-line no-undef
  LazyLoadingImage: LazyImageLoader
};
globalThis.HV.Tracking = {};
globalThis.HV.Blogs = {};

globalThis.isClient = typeof window === 'object';

// eslint-disable-next-line
globalThis.isMobileApp =
  typeof window === 'object' &&
  new URLSearchParams(window.location.search).get('platform') === 'mobile-app';

KlevuConfig.init({
  url: RailsVars.KLEVU_SEARCH_URL,
  apiKey: RailsVars.KLEVU_API_KEY
});
